import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import { graphql } from "gatsby"
import Header from "../../components/ui/base/layout/header"
import TourCard from "../../components/ui/base/TourCard"
import Section from "../../components/ui/base/Section"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import { getLocationPictures } from "../../util/tour"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useTranslation } from "react-i18next"
import {
  BASE_URL,
  DESTINATIONS_URL,
  PHOTO_TOURS_URL,
} from "../../util/constants"
import GallerySection from "../../components/ui/extended/GallerySection"

const LocationPage = ({ data }) => {
  const location = data.strapiLocation
  const pictures = getLocationPictures(location)

  const { i18n: l } = useTranslation()
  const destinationLink = `${BASE_URL[l.language]}/${
    PHOTO_TOURS_URL[l.language]
  }/${DESTINATIONS_URL[l.language]}/${location.state.slug}/${location.slug}/`

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        title={location.title}
        language={l.language}
        description={location.description}
        canonical={destinationLink}
      />
      <Header
        theme="dark"
        size={"compact"}
        img={
          pictures[0]
            ? pictures[0].localFile.childImageSharp.gatsbyImageData
            : ""
        }
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  Foto di {location.title} in {location.state.name}
                </Typography>
                <Breadcrumbs
                  elements={[
                    {
                      name: "WeShoot",
                      path: "/",
                    },
                    {
                      name: "Viaggi Fotografici",
                      path: "/viaggi-fotografici/",
                    },
                    {
                      name: "Destinazioni",
                      path: "/viaggi-fotografici/destinazioni/",
                    },
                    {
                      name: location.state.name,
                      path: `/viaggi-fotografici/destinazioni/${location.state.slug}/`,
                    },
                    {
                      name: location.title,
                      path: `/viaggi-fotografici/destinazioni/${location.state.slug}/${location.slug}/`,
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section
        id={"gallery"}
        variant={"dark"}
        className={classnames([styles.gallery])}
        title={`Le foto di ${location.title}`}
        subtitle={location.description}
      >
        {location && pictures && <GallerySection pictures={pictures} />}
      </Section>

      <Section
        variant={"grey"}
        title={`Scatta con WeShoot nella location ${location.title} 😍`}
        subtitle={`Viaggi fotografici in ${location.state.name} dove puoi fotografare ${location.title} `}
      >
        <Row>
          {data?.allStrapiTour.edges?.map(tour => {
            return (
              <Col lg={4} md={6} className="zoomIn mb-2" key={tour.node.id}>
                <TourCard tour={tour.node} />
              </Col>
            )
          })}
        </Row>
      </Section>
    </>
  )
}

export default LocationPage

export const query = graphql`
  query LocationQuery($slug: String!) {
    strapiLocation(slug: { eq: $slug }) {
      description
      slug
      title
      state {
        name
        slug
        description
      }
      pictures {
        title
        id
        image {
          localFile {
            ...ImageFragment
          }
        }
      }
    }
    allStrapiTour(
      filter: { locations: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          image {
            url
            localFile {
              ...ImageFragment
            }
          }
          price
          slug
          states {
            slug
          }
          places {
            slug
          }
          sessions {
            end
            id
            sessionId
            start
            status
            maxPax
            balance
            price
            deposit
            currency
            users {
              id
              firstName
              instagram
              profilePicture {
                localFile {
                  ...ImageFragment
                }
              }
            }
          }
          excerpt
          experienceLevel
          title
        }
      }
    }
  }
`
